import { useContext} from "react";
import Input from "../../../Components/Input/Input";
import Button from "../../../Components/Button/Button";
import { ExportExcel } from "../../../Components/ExportExcel";
import { AuthContext } from "../../../Components/Context";
import { auth } from "../../../Firebase/Config";
import { useNavigate } from "react-router";
import { getAllDoc } from "../../../MiddleWare/Firebase-functions";
import { vendorsCollections } from "../../../Firebase/Collections";
import { DateDiff, getHours, setTime } from "../../../Helper/HelperFun";
import { FetchMagentoVendors } from "../../../MiddleWare/MagneoVendors";
import {PermissionsGate} from "../../../Route/PermissionsAccess";
import {UserPermissions} from "../../../Services/UserPermissions";

export const NavBar = ({ setSearch, orders }) => {
  const navigate = useNavigate();
  const { setCurrentUser, setLoading, companyInfo } = useContext(AuthContext);

  const ExportOrders = async () => {
    let data = orders.map((e) => ({
      subOrder: e.vendor_order_with_classification,
      itemName: e.name,
      note: e.notes
        .map((d) => d.createAt + "  " + d.createBy + ":" + d.note)
        .toString(),
      price: Number(e.original_price),
      // cusotmer_group:e.cusotmer_group,
      sku: e.sku,
      in_warehouse: e.in_warehouse,
      vendorName: e.vendor_name,
      status: e.status,
      isSingle:e.vendor_order_with_classification.includes("S") ?"Single":"Multiple",
      confirmAt: e.confirmation_date,
      created_at: e.created_at,
      isLate:
        DateDiff(
          new Date(),
          getHours(e.confirmation_date) < companyInfo?.start_hour
            ? setTime(e.confirmation_date, companyInfo?.shift_hour)
            : e.confirmation_date
        ) >
        (e.in_warehouse == 1
          ? companyInfo?.in_warehouse_time
          : companyInfo?.out_warehouse_time),
      item_img: e.image_url,
      hourLate: DateDiff(
        new Date(),
        getHours(e.confirmation_date) < companyInfo?.start_hour
          ? setTime(e.confirmation_date, companyInfo?.shift_hour)
          : e.confirmation_date
      ),
      pick_status:e.isPick>=1 ?1:0,
      paymentMethod:e.payment_method

    }));
    ExportExcel(data);
  };

  const handelLogout = () => {
    navigate("/Login");
    setCurrentUser(null);
    auth.signOut();
  };

  const CreatePickups = async () => {
    setLoading(true);
    let data = await getAllDoc(vendorsCollections);
    let magentVendors = await FetchMagentoVendors();
    let Picks = orders
      .filter((e) => e.in_warehouse == 0)
      .map((e) => ({
        car:
          data.filter(
            (v) =>
              v.id.trim().toLowerCase() == e.vendor_name.trim().toLowerCase()
          )[0]?.car || 0,
        VendorName: e.vendor_name,
        PickUps_Address:
          magentVendors.filter((v) => v.label.trim() == e.vendor_name.trim())[0]
            ?.address || "",
        Vendor_Mobile:
          magentVendors.filter((v) => v.label.trim() == e.vendor_name.trim())[0]
            ?.mobile || "",
        Qty: parseInt(e.qty_ordered),
        SubOrder: e.vendor_order_with_classification,
        Item_Name: e.name,
        image_url: e.image_url,
        pick_status:e.isPick>=1 ?1:0
      }));
    Picks = Picks.sort((a, b) => a.car - b.car);
    ExportExcel(Picks);
    setLoading(false);
  };

  return (
    <>
      <div className="container">
        <div className="navbar-wrapper">
          <div className="logo-search-wrapper">
            <div className="logo" style={{ marginRight: "10px" }}>
              <img width="80px" alt="logo" src="/Images/tamata_logo.svg" />
            </div>
            <Input
              id="search"
              style="input search"
              placeholder="Searching.... "
              type="text"
              onchange={(e) => setSearch(e)}
            />
            <div className="footer" style={{marginLeft:"20px",cursor:"pointer"}}>
              <PermissionsGate permission={UserPermissions.pickUp}>
              <div className="title" onClick={()=>navigate("Manage_opertaion")}>
              Vendor
            </div>
              <div className="title" style={{marginLeft:"10px"}} onClick={()=>navigate("Manage_opertaion/cars")}>
              Manage Pick Up
            </div>
        
              </PermissionsGate>
              <div className="title" style={{marginLeft:"10px"}} onClick={()=>navigate("Manage_opertaion/cancel")}>
              Cancel
            </div>
          </div>
          </div>

          

          <div>
            <Button
              color="C2578AF"
              style="button C2578AF"
              onclick={() => CreatePickups()}
              title="Create Pick Up"
            />
            {/* {PickPrint.length !== 0 && (
              <ReactToPrint
                trigger={() => (
                  <button className="button ceb0028">Print</button>
                )}
                content={() => {
                  return Print();
                }}
              />
            )} */}
            <span>
              <Button
                color="c70b44d"
                style="button c70b44d"
                id="log-out"
                onclick={() => ExportOrders()}
                title="Export"
              />
            </span>

            <Button
              color="cffffff"
              style="button"
              id="log-out"
              title="Log Out"
              onclick={() => handelLogout()}
            ></Button>
          </div>
        </div>
        {/* <div ref={refComp} id="Invoice_print">
          Tamata Pick Ups {dayjs(new Date()).format("YYYY-MM-DD")}
          {PickPrint.length !== 0 && <PickUpsPrint orders={PickPrint} />}
        </div> */}
      </div>
    </>
  );
};