import dayjs from "dayjs";
import { IoIosAlert, IoMdDoneAll } from "react-icons/io";
import { MdDone } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { DateDiff, getHours, setTime } from "../../../Helper/HelperFun";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../Components/Context";
import { CurrencyIqFormat } from "../../../Components/Input/CurrencyIqFormat ";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Popup from "reactjs-popup";
import { CreateNote } from "./CreateNote";
import Select from "react-select";
import { empty_select } from "../../../styles/custom_select";
import { FetchAPI } from "../../../Components/Utils/FetchAPI";
import { PermissionsGate } from "../../../Route/PermissionsAccess";
import { UserPermissions } from "../../../Services/UserPermissions";
import { StatusCom } from "../../../Components/StatusCom";
import TamataStatus from "../../../Data/TamataStatus.json";
import { HiSortDescending } from "react-icons/hi";
import Provinces from "../../../Data/Provinces.json";
import moment from "moment";
import { GiPriceTag } from "react-icons/gi";
import { customer_group } from "../../../Data/customer_group";
import { CallingStatus } from "../../../Data/CallingStatus";
import {
  setDocumnet,
  updateDocument,
} from "../../../MiddleWare/Firebase-functions";
import { orderV2sCollection, ordersCollection } from "../../../Firebase/Collections";
import { toast } from "react-toastify";
import { HasPermission } from "../../../Helper/HasPermissions";
import { AiFillDelete } from "react-icons/ai";
import { MdOutlinePayment } from "react-icons/md";
export const OrderTable = ({ wearhouse, setWearhouse, orders }) => {
  const [vendorSort, setVendorSort] = useState(false);
  const [priceSort, setPriceSort] = useState(false);
  const [pickSort, setIsPickSort] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [status, setStatus] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [callingStatus, setCallingStatus] = useState(null);
  const containerRef = useRef(null);

  const { companyInfo, setPendingOrders } =
    useContext(AuthContext);
  var wearhouseType = [
    {
      label: "In Wearhouse",
      value: "1",
    },
    {
      label: "Out Wearhouse",
      value: "0",
    },
  ];
  var pickStatus = [
    {
      label: "Is Pick",
      value: 1,
    },
    {
      label: "Not Pick",
      value: 0,
    },
  ];
  var paymentMethods = [
    {
      label: "Cash",
      value: "cashondelivery",
    },
    {
      label: "BNPL",
      value: "bnpl",
    },
    {
      label: "Tabadul",
      value: "tabadul",
    },
  
  ];
  const paymentMethodMapper=(method)=>{
    const methodMapping = {
      "tabadul": "Tabadul",
      "cashondelivery": "Cash",
      "bnpl": "BNPL",
      // Add more mappings as needed
  };
  return methodMapping[method] || "";


  }
  const CallingStatusColor={
Unreachable:"#eb0028",
Idle:"#ff9e1b",
Answered:"#6cc24a"
  };
  var totalOrders = orders
    .filter((e) => (status ? e.status == status.label : e))
    .filter((e) => (pickSort ? e.isPick == pickSort.value : e))
    .filter((e) => (vendor ? e.vendor_name == vendor.label : e))
    .filter((e) => (callingStatus ? e.callingStatus == callingStatus.label : e))
    .filter((e) => (selectedPaymentMethod ? e.payment_method == selectedPaymentMethod.value : e))
    .sort((a, b) =>
      vendorSort ? a.vendor_name.localeCompare(b.vendor_name) : b
    )
    .sort((a, b) => (priceSort ? b.original_price - a.original_price : b));

  const handleChange = async (order, event) => {
    const value = event.target.value;
    await setDocumnet(orderV2sCollection,order.vendor_order_with_classification,{
      callingStatus:value
    })
    toast.success("تمت العلمية بنجاح");
    setPendingOrders((prev) => {
      const index = prev.findIndex(
        (e) =>
          e.vendor_order_with_classification ==
          order.vendor_order_with_classification
      );

      prev[index].callingStatus = value;
      console.log(prev);
      return [...prev];
    });
  };

  const DeletePicked = async (order) => {
    await updateDocument(
      orderV2sCollection,
      order.vendor_order_with_classification,
      {
        isPick: 0,
        pickBy: "",
        PickAt: "",
        pickStatus:0
      }
    );

    setPendingOrders((prev) => {
      const index = prev.findIndex(
        (e) =>
          e.vendor_order_with_classification ==
          order.vendor_order_with_classification
      );
      prev[index].isPick = 0;
      prev[index].pickBy = "";
      prev[index].PickAt = "";

      console.log(prev);
      return [...prev];
    });

    toast.success("تمت العملية بنجاح");
  };
  const handleOnScroll=()=>{
    const { scrollTop,clientHeight } = containerRef.current;
    if(scrollTop%clientHeight<clientHeight) console.log("Get");
  }

  const showDifference=(row)=>{

    console.log(dayjs(new Date()).diff(new Date(row.confirmation_date),"hour")>10)
  };



  return (
    <>
      <div className="table_container">
        <div className="thead">
          <span className="fixed_text">#</span>
          <span className="small_cell">Sub Order</span>
          <span className="small_cell">Item Name</span>
          <span
            onClick={() => setPriceSort((e) => !e)}
            className="small_cell"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            Price{" "}
            <GiPriceTag
              fill="#2578AF"
              style={{ margin: "10px" }}
              size={"15px"}
            />
          </span>
          <span>
            <Select
              options={totalOrders.map(e=>({label:e.vendor_name,value:e.vendor_name}))}
              name="colors"
              value={vendor}
              onChange={(val) => {
                setVendor(val);
              }}
              isClearable={true}
              placeholder="Vendor"
              styles={empty_select()}
            />
          </span>
          <div
            onClick={() => setVendorSort((e) => !e)}
            style={{ marginTop: "10px", cursor: "pointer" }}
          >
            <HiSortDescending fill="#2578AF" size={"15px"} />
          </div>
          <span className="small_cell">Confirm At</span>
          <span>
            <Select
              options={wearhouseType}
              name="colors"
              value={wearhouse}
              onChange={(val) => {
                setWearhouse(val);
              }}
              isClearable={true}
              placeholder="Wearhouse"
              styles={empty_select()}
            />
          </span>

          <span>
            <Select
              options={TamataStatus}
              name="colors"
              value={status}
              onChange={(val) => {
                setStatus(val);
              }}
              isClearable={true}
              placeholder="Status"
              styles={empty_select()}
            />
          </span>

          <span className="fixed_text">Late Hours</span>
          <span className="fixed_text">Note</span>
          <span>
            <Select
              options={Object.keys(CallingStatusColor).map(e=>({value:e,label:e}))}
              name="colors"
              value={callingStatus}
              onChange={(val) => {
                setCallingStatus(val);
              }}
              isClearable={true}
              placeholder="Calling"
              styles={empty_select()}
            />
          </span>
          
          <span>
            <Select
              options={pickStatus}
              name="colors"
              value={pickSort}
              onChange={(val) => {
          
                setIsPickSort(val);
              }}
              isClearable={true}
              placeholder="PickUp Status"
              styles={empty_select()}
            />
          </span>
          <span>
            <Select
              options={paymentMethods}
              name="colors"
              value={selectedPaymentMethod}
              onChange={(val) => {
                setSelectedPaymentMethod(val);
              }}
              isClearable={true}
              placeholder="Payment Method"
              styles={empty_select()}
            />
          </span>
          <span className="fixed_text"></span>
        </div>
        <div className="row_odd"           ref={containerRef}
          onScroll={handleOnScroll}
          style={{ overflowY: "scroll", height: "400px" }}>
          {orders.length !== 0 ? (
            totalOrders.map((e, index) => (
              <div className="body_container" key={index * 4} onClick={()=>showDifference(e)}>
                <span className="fixed_text">{index + 1}
             </span>
                <span className="small_cell">
                  {e.vendor_order_with_classification}
                </span>
                <span className="small_cell">{e.name}</span>
                <span className="small_cell">
                  <CurrencyIqFormat val={e.original_price} />
                </span>
                <span className="small_cell">{e.vendor_name}</span>

                <span className="small_cell">{e.confirmation_date}</span>
                <span className="small_cell">{e.in_warehouse}</span>
              
                <span>{e.status}</span>
                <span className="fixed_text">
                  <StatusCom
                    status={
                      DateDiff(
                        new Date(),
                        getHours(e.confirmation_date) < companyInfo?.start_hour
                          ? setTime(
                              e.confirmation_date,
                              companyInfo?.shift_hour
                            )
                          : e.confirmation_date
                      ) >=
                      (e.in_warehouse == 1
                        ? companyInfo?.in_warehouse_time
                        : companyInfo?.out_warehouse_time)
                    }
                    text={DateDiff(
                      new Date(),
                      getHours(e.confirmation_date) < companyInfo?.start_hour
                        ? setTime(e.confirmation_date, companyInfo?.shift_hour)
                        : e.confirmation_date
                    )}
                  />
                </span>
                <span className="fixed_text">
                  <Popup
                    className="my-popup-content"
                    trigger={
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        {e.notes.length !== 0 ? (
                          "Notes"
                        ) : (
                          <FaRegEdit size={"15px"} fill="#2578AF" />
                        )}
                      </button>
                    }
                    position="top center"
                  >
                    <PermissionsGate permission={UserPermissions.add_note}>
                      <CreateNote order={e} />
                    </PermissionsGate>
                  </Popup>
                </span>
                <select
                  className="CallingStatusStyled"
                  disabled={!HasPermission(UserPermissions.updateCallingStatus)}
                  value={e.callingStatus}
                  style={{
                    backgroundColor: CallingStatusColor[e.callingStatus],
                  }}
                  onChange={(select) => handleChange(e, select)}
                >
                  {Object.keys(CallingStatus).map((option) => (
                    <option
                      key={CallingStatus[option].value}
                      value={CallingStatus[option].value}
                    >
                      {CallingStatus[option].value}
                    </option>
                  ))}
                </select>

                <span className="fixed_text">
                  {e.isPick >= 1 && (
                    <Popup
                      className="my-popup-content"
                      trigger={
                        <button
                          style={{
                            border: "none",
                          }}
                        >
                          <p className="isPicked">P</p>
                        </button>
                      }
                      position="top center"
                    >
                      <div
                        className=""
                        style={{ fontSize: "12px", color: "rgb(138 127 127)" }}
                      >
                        <span>
                          Pick At: {e.PickAt} by:{e.pickBy}-{e.isPick}
                        </span>
                        <PermissionsGate permission={UserPermissions.delete_pick}>
                          <span onClick={() => DeletePicked(e)}>
                            -
                            <AiFillDelete
                              fill="red"
                              size={"20px"}
                              cursor={"pointer"}
                            />{" "}
                          </span>
                        </PermissionsGate>
                      </div>
                    </Popup>
                  )}
                </span>
                <span className="fixed_text">
                {paymentMethodMapper(e.payment_method||"")} 
                </span>
                <span className="fixed_text">
                  <PhotoProvider>
                    <PhotoView src={e.image_url}>
                      <img src={e.image_url} width="70px" />
                    </PhotoView>
                  </PhotoProvider>
                </span>
              </div>
            ))
          ) : (
            <div>NO Orders</div>
          )}
        </div>
      </div>
    </>
  );
};