import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { usersCollection } from "../../Firebase/Collections";
import { auth } from "../../Firebase/Config";
import { getSingleDoc } from "../../MiddleWare/Firebase-functions";
import { AiFillHeart } from "react-icons/ai";
import { BsSuitHeartFill } from "react-icons/bs";
import { FaSmileBeam } from "react-icons/fa";

export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [magentVendors, setMagentoVendors] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [vendorSelect, setVendorSelect] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getSingleDoc(usersCollection, user.uid).then((e) => {
          setLoading(false);
          setPending(false);
          setCurrentUser(e);
          console.log(e)
          if (e.role.includes("agent")) {
            navigate("tamata_opration/vendorPickups");
          }
          if(e.role.includes("operation")){
            navigate("tamata_opration");
          }
        });
      } else {
        setPending(false);
        setLoading(false);
        setCurrentUser(null);
        navigate("/login");
      }
    });
    return () => unsubscribe();
  }, []);
  if (pending) {
    {
      return (
        <>
          <div className="loading_screen">
            <div>
              <img src="/Images/tamata_gif.gif" width="50%" />
            </div>
          </div>
        </>
      );
    }
  }
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        setLoading,
        setCurrentUser,
        setCompanyInfo,
        companyInfo,
        magentVendors,
        setMagentoVendors,
        pendingOrders,
        setPendingOrders,
        setVendorSelect,
        vendorSelect

      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
