import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Components/Context";
import { FetchAPI } from "../../../Components/Utils/FetchAPI";
import {API_URL} from "../../../Firebase/API_URL";
import { NavBar } from "./NavBar";
import { OrderTable } from "./OrderTable";
export const Dashboard = () => {
  const { pendingOrders } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const [wearhouse, setWearhouse] = useState(null);
  const [totalNewOrder, setTotalNewOrder] = useState(0);

  let data = pendingOrders.filter((e) =>
    wearhouse ? e.in_warehouse == wearhouse.value : e
  );
  useEffect(() => {
    const fetchDate = async () => {
      let totalPending = await FetchAPI(
        `${API_URL}/v1/StatusCount?status=pending`,
        {
          method: "GET",
        }
      );
      setTotalNewOrder(totalPending.data||0);
      console.log(totalPending);
    };
    fetchDate();
  }, []);
  return (
    <>
      <NavBar
        setSearch={setSearch}
        orders={data}
      />
      <OrderTable
        orders={data.filter((e) =>
          e.vendor_order_with_classification.includes(search || "")
        )}
        wearhouse={wearhouse}
        setWearhouse={setWearhouse}
      />
      <div className="footer">
        <div>
          <span className="title">Total New Order : </span>
          <span>{totalNewOrder || 0}</span>
        </div>
        <div>
          <span className="title">Total confirmed : </span>
          <span>{data.filter((e) => e.status == "confirmed").length}</span>
        </div>
        <div>
          <span className="title">Total Processing : </span>
          <span>{data.filter((e) => e.status == "processing").length}</span>
        </div>
      </div>
    </>
  );
};