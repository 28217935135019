import {API_URL} from "../Firebase/API_URL";

export const ReqOrdersTypes = async (
  id,
  isTotal = false,
  currentPage = 0
) => {
  if (!id) return [];

  var response = await fetch(
    `${API_URL}/v1/totalOrder?id=${id}&current_page=${currentPage}&isTotal=${isTotal}`
  );

  const MagentoOrders = await response.json();
  if (!MagentoOrders.status) return [];
  return [...MagentoOrders.data];
};
