import {useContext} from "react";
import {AuthContext} from "./Context";

export const StatusCom = ({text,status}) => {
    const {companyInfo}=useContext(AuthContext)
    return (
      <>
        <span className={`${status?"late":""}`}>{text}</span>
      </>
    );
  };