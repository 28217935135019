import { useEffect, useState, useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import Input from "../../../Components/Input/Input";
import { CurrencyIqFormat } from "../../../Components/Input/CurrencyIqFormat ";
import useLongPress from "../../../MiddleWare/useLongPress ";
import dayjs from "dayjs";
import {OrderCard} from "./OrderCard";
import {
  AgentsCollections,
  orderV2sCollection,
  vendorsCollections,
} from "../../../Firebase/Collections";
import {
  getAllDoc,
  getQueryDocs,
  setDocumnet,
  updateDocument,
} from "../../../MiddleWare/Firebase-functions";
import {ExportExcel} from "../../../Components/ExportExcel";
import {FetchAPI} from "../../../Components/Utils/FetchAPI";
import ImgsViewer from "react-images-viewer";
import moment from "moment";
import {documentId, Firestore} from "firebase/firestore";
import {toast} from "react-toastify";
import {addDataIntoSesstion} from "../../../MiddleWare/CacheHelper";
import {AuthContext} from "../../../Components/Context";
import {API_URL} from "../../../Firebase/API_URL";

export const CardsList = ({setTamataVendors}) => {
  const [search, setSearch] = useState("");
  const [vendorOrders, setVendorOrders] = useState([]);
  const {currentUser,setLoading}=useContext(AuthContext)
  const {state} = useLocation();
  const navigate = useNavigate();
  const getOrders = useCallback(async () => {
    var ordersPicked = [];
    setLoading(true);
    var {data} = await FetchAPI(
      `${API_URL}/v1/orders_status?pageSize=500&vendor_id=${Number(
        state.vendorId
      )}&vendor_name=${state.name.replaceAll(" ","%20")}`,
      {
        method: "GET",
      }
    );
    
    console.log(data)
    data = data.map((e) => ({
      ...e,
      isSelected: false,
      created_at: moment(e.confirmation_date)
        .add(3, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
    })); 
    console.log(currentUser)


  ordersPicked = await getQueryDocs(orderV2sCollection, documentId(), "in", [
    ...data.map((e) => e.vendor_order_with_classification),
  ]);
  

console.log(ordersPicked)
    data = data.map((e) => ({
      ...e,
      isPick:
        ordersPicked.filter(
          (c) => c.id == e.vendor_order_with_classification
        )[0]?.pickStatus || 0,
    }));
    console.log(data)

    setVendorOrders([...data]);
    setLoading(false)

  }, []);
  useEffect(() => {
    getOrders();
  }, []);

  const HandelPickItem = (data) => {

    setDocumnet(orderV2sCollection, data.vendor_order_with_classification, {
      pickStatus: 1,
      createBy: currentUser.name,
      id: data.vendor_order_with_classification,
      vendorName: data.vendor_name,
      PickAt: dayjs(new Date()).format("YYYY-MM-DD hh:mm"),
    });
    updateDocument(AgentsCollections,"CacheVendors",{
      [data.vendor_name]:{
        isFirstTime:true
      }
    })
    setVendorOrders((prev) => {
      prev = prev.filter(
        (e) =>
          e.vendor_order_with_classification !==
          data.vendor_order_with_classification
      );
      return prev;
    });
    toast.success("تم التحميل");
  };
  const handelLeave = () => {

    if (
      vendorOrders.length == vendorOrders.filter((e) => e.isPick >= 1).length
    ) {
      setTamataVendors((prev) => {
        prev = prev.filter((pre) => pre.vendorId !== state.vendorId);
        addDataIntoSesstion("agentsVendors", prev);
        return [...prev];
      });
    }
    navigate(-1);
  };
  
  return (
    <>
      <div className="order_page">
        <div  style={{display:"flex",justifyContent:"center"}}>
          <div >
            <div>Total Orders : {vendorOrders.filter((e) => e.isPick < 1).length}</div>
          </div>
          <div>
          </div>
        </div>
        <div className="search_container">
          <Input
            id="search"
            placeholder="Searching for order or item....."
            type="text"
            style="input search-order"
            value={search}
            onchange={(e) => setSearch(e)}
          />
        </div>
        <div className="orders_list">
          {vendorOrders.length  ? (
            vendorOrders
              .filter(
                (e) =>
                  e.vendor_order_with_classification.includes(search || "") ||
                  e.name.includes(search || "")
              )
              .map((e, index) => (
                <OrderCard
                  setVendorOrders={setVendorOrders}
                  data={e}
                  HandelPickItem={HandelPickItem}
                  index={index}
                  key={index * Math.random()}
                />
              ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontWeight: "600",
                fontSize: "20px",
                color: "red",
              }}
            >
              <img src="/Images/order-details-order-status.png" width="50%" />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            right: "0",
            bottom: "-2px",
          }}
        >
          <span className="Leave_btn" onClick={() => handelLeave()}>
            Leave
          </span>
        </div>
      </div>
    </>
  );
};
