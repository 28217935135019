import { useContext, useState } from "react";
import { AiOutlineReload } from "react-icons/ai";
import { useNavigate } from "react-router";
import Button from "../../../Components/Button/Button";
import { WebcamComponent } from "../../../Components/Camera";
import { AuthContext } from "../../../Components/Context";
import { auth } from "../../../Firebase/Config";
import {CacheVendor} from "./Modal/CacheVendor";
import {FetchAPI} from "../../../Components/Utils/FetchAPI";
import {API_URL} from "../../../Firebase/API_URL";
import {getAllDoc} from "../../../MiddleWare/Firebase-functions";
import {vendorsCollections} from "../../../Firebase/Collections";
import {PermissionsGate} from "../../../Route/PermissionsAccess";
import {UserPermissions} from "../../../Services/UserPermissions";

export const NavBar = ({ fetVendorOrders }) => {
  const [isOpen, setIsOpen] = useState(false);
const {setLoading,currentUser,setCurrentUser}=useContext(AuthContext);
  const navigate = useNavigate();
  const handelLogout = () => {
    navigate("/Login");
    setCurrentUser(null);
    auth.signOut();
  };
const handelCache=async()=>{
  setLoading(true);
 await FetchAPI(
    `${API_URL}/v1/vendor_update`,
    {
      method: "GET",
    }
  );
  fetVendorOrders(true);
}
  return (
    <>
      <div className="nav">
        <div onClick={() => fetVendorOrders(true)}>
         {currentUser.role=="agent"&& <AiOutlineReload strokeWidth={"21px"} size={"26px"} />}
        </div>
        <PermissionsGate permission={UserPermissions.update_cache}>

          <img width="25" alt="logo" src="/Images/update_icon.svg"  onClick={()=>setIsOpen(true)}/>
        </PermissionsGate>
        <div>PickUpApp</div>
        <div>
          <span>
            <Button
              color="c66BFBF"
              style="button"
              title="LogOut"
              onclick={() => handelLogout()}
            />
          </span>
        </div>
      </div>{

      isOpen&&  <CacheVendor open={isOpen} setOpen={setIsOpen} handelCache={handelCache}/>
      }
    </>
  );
};
