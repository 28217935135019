import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    };
    const app = firebase.initializeApp(firebaseConfig);
    export const db = getFirestore(app);
    export const storage=firebase.storage();
    export const auth = app.auth();