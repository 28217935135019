import {collection} from "firebase/firestore";
import {db} from "./Config";


export const usersCollection=collection(db,"users")
export const companiesCollections=collection(db,"companies")
export const vendorsCollections=collection(db,"vendors")
export const cancelOrderCollection=collection(db,"cancelOrderCollection")
// export const magentVendorsCollections=collection(db,"magentVendors")
export const ordersCollection=collection(db,"orders")
export const orderV2sCollection=collection(db,"orderV2")
export const AgentsCollections=collection(db,"Agents")
